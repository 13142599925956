<template>
  <b-modal v-model="dialog.active" size="xl"  centered hide-footer no-close-on-backdrop body-class="p-0" @hidden="reset">
    <template #modal-title>{{ msg("channelManager") }}</template>
    <div class="p-3" v-if="connectionData.selectedBranch == null">
      <h5 class="text-center">Select a Branch</h5>
      <v-select
          :dir="isRTL ? 'rtl' : 'ltr'"
          v-model="selectedBranch"
          placeholder="Branch"
          :options="allBranches"
          class="w-100 mb-1"
          label="name"
          :reduce="(branch) => branch.id"
          :clearable="false"

      />
      <div>
        <b-button block variant="success" @click="initConnection" :disabled="selectedBranch == null || selectedBranch === ''"><i class="fa-solid fa-right-to-bracket"></i> Continue</b-button>
      </div>
    </div>
    <div v-if="dialog.active && connectionData.selectedBranch != null">
      <object :data="connectionData.url" class="full-size custom-scrollbar"></object>
    </div>
  </b-modal>
</template>
<script>
import {mapGetters} from "vuex";

const FRAME_URL = "/frames/channel-manager/?branch=";

export default {
  name: "channelManagerModal",
  data: () => ({
    dialog: {
      active: false,
    },
    selectedBranch: null,
    connectionData: {
      selectedBranch: null,
      url: null
    }
  }),
  computed:{
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  methods:{
    show(){
      this.dialog.active = true;
      if (this.allBranches.length === 1) {
        this.selectedBranch = this.allBranches[0].id;
        this.initConnection();
      }
    },
    reset(){
      this.dialog.active = false;
      this.selectedBranch = null;
      this.connectionData.selectedBranch = null;
      this.connectionData.url = null;
    },
    initConnection(){
      this.connectionData.selectedBranch = this.selectedBranch;
      this.connectionData.url = FRAME_URL + this.selectedBranch;
    }
  }
}
</script>



<style scoped>

</style>
