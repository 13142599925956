<template>
  <portal to="breadcrumb-right">
    <div style="letter-spacing: 5px">
      <slot name="default"></slot>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'breadcrumbButtons',
}
</script>

<style scoped>

</style>
